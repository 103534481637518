import React, { useEffect, useState } from "react";
import image1 from "../assets/homeImage1.png";
import image2 from "../assets/homeImage2.svg";
import image3 from "../assets/homeImage3.svg";
import image4 from "../assets/homeImage4.svg";
import image5 from "../assets/homeImage5.svg";
import image6 from "../assets/homeImage6.svg";
import logo from "../assets/ClusterCode Logo Transpanent BG.png";
import bgImage from "../assets/empty-room-with-chairs-desks.jpg";
import "../css/home.css";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faSquareFacebook,
  faSquareGithub,
  faSquareInstagram,
  faSquareWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

export default function Home() {
  const images = [image2, image3, image4, image5, image6];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsTransitioning(true);

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % images.length;
          setIsTransitioning(false);
          return nextIndex;
        });
      }, 500);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div
      className="w-full min-h-svh flex justify-center items-center"
      id="Home"
    >
      <div className="flex md:flex-col lg:flex-row flex-col justify-center items-center w-5/6 h-full md:pt-[100px] lg:pt-[0px] pt-[100px] gap-5 lg:gap-0 md:gap-14">
        <div className="md:w-full lg:w-1/2 w-full flex justify-center lg:h-full md:h-1/2 h-1/2 flex-col gap-5">
          <span
            className="text-white font-semibold lg:text-5xl md:text-5xl text-4xl poppins"
            data-aos="zoom-in" data-aos-duration="1000"
          >
            Why{" "}
            <span className="font-bold protestRevolution lg:text-6xl md:text-6xl text-5xl text-[#cd9705]">
              ClusterCode ?
            </span>
          </span>
          <p className="text-white poppins" data-aos="zoom-in" data-aos-duration="1000">
            At ClusterCode, we specialize in automating and digitizing your
            daily business operations, ensuring top-notch security while driving
            efficiency and boosting the productivity of your processes. Our
            tailored solutions empower your business to thrive in the digital
            age.
          </p>
          <Link
            spy={true}
            smooth={true}
            data-aos="zoom-in" data-aos-duration="1000"
            to="Contact"
            className="bg-[#cd9705] w-1/3 py-2 rounded-md flex cursor-pointer justify-center items-center font-semibold poppins z-10"
          >
            Contact Us
          </Link>
          <div className="flex items-center justify-center lg:justify-start md:justify-start gap-8 z-10">
            <FontAwesomeIcon
              data-aos="zoom-in" data-aos-duration="1000"
              onClick={() => {
                window.open("https://www.facebook.com/clustercodecc", "_blank");
              }}
              className="text-white text-2xl cursor-pointer"
              icon={faSquareFacebook}
            />
            <FontAwesomeIcon
              data-aos="zoom-in" data-aos-duration="1000"
              onClick={() => {
                window.open(
                  "https://www.instagram.com/clustercodecc?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
                  "_blank"
                );
              }}
              className="text-white text-2xl cursor-pointer"
              icon={faSquareInstagram}
            />
            <FontAwesomeIcon
              data-aos="zoom-in" data-aos-duration="1000"
              onClick={() => {
                window.open(
                  "https://api.whatsapp.com/send?phone=94772064930&text=Hi",
                  "_blank"
                );
              }}
              className="text-white text-2xl cursor-pointer"
              icon={faSquareWhatsapp}
            />
            <FontAwesomeIcon
              data-aos="zoom-in" data-aos-duration="1000"
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/clustercodecc/",
                  "_blank"
                );
              }}
              className="text-white text-2xl cursor-pointer"
              icon={faLinkedin}
            />
          </div>
        </div>
        <div className="md:w-full lg:w-3/4 lg:block md:block hidden lg:h-full md:h-[400px] h-1/2 z-10">
          <div className="flex justify-center items-center" data-aos="zoom-in" data-aos-duration="1000">
            <img
              src={images[currentImageIndex]}
              className={`w-2/3 md:h-[400px] image ${
                isTransitioning ? "fade" : ""
              }`}
              alt="open"
            />
          </div>
        </div>
      </div>
      <img
        src={bgImage}
        className="absolute lg:block md:hidden hidden opacity-[0.05] h-screen w-screen z-0"
        alt="cluster code logo"
      />
    </div>
  );
}
