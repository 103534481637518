import React from "react";
import "../css/product.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBootstrap,
  faCss,
  faHtml5,
  faJava,
  faPhp,
  faReact,
  faSquareJs,
} from "@fortawesome/free-brands-svg-icons";
import tailwind from "../assets/tailwind-css-svgrepo-com.svg";
import mySQL from "../assets/mysql-svgrepo-com.svg";
import webDesign from "../assets/web icon.png";
import webApplication from "../assets/online-shopping.png";
import mobileApplication from "../assets/mobile-coding.png";
import desktopApplication from "../assets/desktop-application.png";
import degitalMarketing from "../assets/degital-media.png";
import grapicDesign from "../assets/grapic-design.png";
import { Link } from "react-scroll";

export default function Products() {
  return (
    <div className="w-full min-h-svh flex flex-col items-center" id="Products">
      <div className="pt-[100px] pb-20 w-full flex flex-col">
        {/* <span className="text-white font-bold poppins text-2xl w-full text-center">
          Integrations
        </span> */}
        <div className="wrapper" data-aos="zoom-in" data-aos-duration="1000">
          <div className="item flex flex-col gap-1 justify-center items-center item1">
            <FontAwesomeIcon
              icon={faJava}
              className="text-7xl text-[#cd9705]"
            />
            <span className="text-[#cd9705] text-lg poppins font-semibold">
              Java
            </span>
          </div>

          <div className="item flex flex-col gap-1 justify-center items-center item2">
            <FontAwesomeIcon icon={faPhp} className="text-7xl text-[#cd9705]" />
            <span className="text-[#cd9705] text-lg poppins font-semibold">
              PHP
            </span>
          </div>

          <div className="item flex flex-col gap-1 justify-center items-center item3">
            <FontAwesomeIcon
              icon={faReact}
              className="text-7xl text-[#cd9705]"
            />
            <span className="text-[#cd9705] text-lg poppins font-semibold">
              React
            </span>
          </div>

          <div className="item flex flex-col gap-1 justify-center items-center item4">
            <FontAwesomeIcon
              icon={faHtml5}
              className="text-7xl text-[#cd9705]"
            />
            <span className="text-[#cd9705] text-lg poppins font-semibold">
              HTML
            </span>
          </div>

          <div className="item flex flex-col gap-1 justify-center items-center item5">
            <FontAwesomeIcon
              icon={faBootstrap}
              className="text-7xl text-[#cd9705]"
            />
            <span className="text-[#cd9705] text-lg poppins font-semibold">
              Bootstrap
            </span>
          </div>

          <div className="item flex flex-col gap-1 justify-center items-center item6 ">
            {/* <FontAwesomeIcon
              icon={faBootstrap}
              className="text-7xl text-[#cd9705]"
            /> */}
            <img alt="def" src={tailwind} className="h-[75px]" />
            <span className="text-[#cd9705] text-lg poppins font-semibold">
              Tailwind
            </span>
          </div>

          <div className="item flex flex-col gap-1 justify-center items-center item7">
            <FontAwesomeIcon
              icon={faSquareJs}
              className="text-7xl text-[#cd9705]"
            />
            <span className="text-[#cd9705] text-lg poppins font-semibold">
              JavaScript
            </span>
          </div>

          <div className="item flex flex-col gap-1 justify-center items-center item8">
            <FontAwesomeIcon icon={faCss} className="text-7xl text-[#cd9705]" />
            <span className="text-[#cd9705] text-lg poppins font-semibold">
              CSS
            </span>
          </div>

          <div className="item flex flex-col gap-1 justify-center items-center item9">
            <FontAwesomeIcon
              icon={faReact}
              className="text-7xl text-[#cd9705]"
            />
            <span className="text-[#cd9705] text-lg poppins font-semibold">
              React Native
            </span>
          </div>

          <div className="item flex flex-col gap-1 justify-center items-center item10">
            {/* <FontAwesomeIcon
              icon={faDatabase}
              className="text-7xl text-[#cd9705]"
            /> */}
            <img alt="def" src={mySQL} className="h-[75px]" />
            <span className="text-[#cd9705] text-lg poppins font-semibold">
              MySQL
            </span>
          </div>
        </div>
      </div>
      <div className="w-5/6 flex flex-col pt-10" data-aos="zoom-in" data-aos-duration="1000">
        <span className="text-4xl font-bold poppins text-white w-full text-center underline underline-offset-4">
          Our <span className="text-[#cd9705]">Services</span>
        </span>
      </div>
      <div className="w-full md:w-5/6 lg:w-5/6 flex h-fit pt-10" data-aos="zoom-in" data-aos-duration="1000">
        <Carousel
          showArrows={true}
          autoPlay={true}
          emulateTouch={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          interval={5000}
          preventMovementUntilSwipeScrollTolerance={true}
          className="w-full"
        >
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="md:w-2/3 lg:w-1/2 w-10/12 rounded-3xl flex flex-col items-center justify-center p-10 bg-[#2b2b2c] lg:h-[400px] h-[550px]">
              <div className="w-[100px]">
                <img alt="def" src={webDesign} className="w-full h-full" />
              </div>
              <span className="text-white poppins font-bold text-xl p-2">
                Web Design
              </span>
              <p className="text-gray-300 poppins text-center p-1">
                We believe web design is more than just creating beautiful
                pages—it’s about crafting seamless user experiences that
                captivate, engage, and deliver real results. With our expert eye
                for detail and passion for innovation, we transform ideas into
                stunning, responsive websites that leave a lasting impression.
              </p>
              <Link
                spy={true}
                smooth={true}
                to="Contact"
                className="bg-[#cd9705] py-1 px-3 ms-1 rounded-md font-semibold poppins cursor-pointer mt-4"
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="md:w-2/3 lg:w-1/2 w-10/12 rounded-3xl flex flex-col items-center justify-center p-10 bg-[#2b2b2c] lg:h-[400px] h-[550px]">
              <div className="w-[100px]">
                <img
                  alt="def"
                  src={webApplication}
                  className="w-full h-full"
                />
              </div>
              <span className="text-white poppins font-bold text-xl p-2">
                Web Application
              </span>
              <p className="text-gray-300 poppins p-1 text-center">
                At ClusterCode, web development is way more than just a few
                lines of code; it's about providing scalable, secure,
                user-friendly solutions to real-world problems. By applying our
                expertise and passion for innovation, we develop powerful web
                applications that assure extraordinary performance and drive
                meaningful results for your business.
              </p>
              <Link
                spy={true}
                smooth={true}
                to="Contact"
                className="bg-[#cd9705] py-1 px-3 ms-1 rounded-md font-semibold poppins mt-4 cursor-pointer"
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="md:w-2/3 lg:w-1/2 w-10/12 rounded-3xl flex flex-col items-center justify-center p-10 bg-[#2b2b2c] lg:h-[400px] h-[550px]">
              <div className="w-[100px]">
                <img
                  alt="def"
                  src={mobileApplication}
                  className="w-full h-full"
                />
              </div>
              <span className="text-white poppins font-bold text-xl p-2">
                Mobile Application
              </span>
              <p className="text-gray-300 poppins p-1 text-center">
                Our team specializes in creating innovative mobile applications,
                providing user-friendly, high-performance solutions to meet
                business needs, ensuring connectivity, competitiveness, and
                ahead in the digital world.
              </p>
              <Link
                spy={true}
                smooth={true}
                to="Contact"
                className="bg-[#cd9705] py-1 px-3 ms-1 rounded-md font-semibold poppins mt-4 cursor-pointer"
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="md:w-2/3 lg:w-1/2 w-10/12 rounded-3xl flex flex-col items-center justify-center p-10 bg-[#2b2b2c] lg:h-[400px] h-[550px]">
              <div className="w-[100px]">
                <img
                  alt="def"
                  src={desktopApplication}
                  className="w-full h-full"
                />
              </div>
              <span className="text-white poppins font-bold text-xl p-2">
                Desktop Application
              </span>
              <p className="text-gray-300 poppins p-1 text-center">
                Our team of experienced developers creates robust, user-friendly
                desktop applications using advanced technologies, enhancing
                productivity and workflows for businesses and personal use.
              </p>
              <Link
                spy={true}
                smooth={true}
                to="Contact"
                className="bg-[#cd9705] py-1 px-3 ms-1 rounded-md font-semibold poppins mt-4 cursor-pointer"
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="md:w-2/3 lg:w-1/2 w-10/12 rounded-3xl flex flex-col items-center justify-center p-10 bg-[#2b2b2c] lg:h-[400px] h-[550px]">
              <div className="w-[100px]">
                <img
                  alt="def"
                  src={degitalMarketing}
                  className="w-full h-full"
                />
              </div>
              <span className="text-white poppins font-bold text-xl p-2">
                Digital Marketing
              </span>
              <p className="text-gray-300 poppins p-1 text-center">
                Our team of experts creates effective digital marketing
                strategies using advertising, social media, content, and email
                marketing, analyzing market trends and data to maximize brand
                visibility, engagement, and conversions.
              </p>
              <Link
                spy={true}
                smooth={true}
                to="Contact"
                className="bg-[#cd9705] py-1 px-3 ms-1 rounded-md font-semibold poppins mt-4 cursor-pointer"
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="md:w-2/3 lg:w-1/2 w-10/12 rounded-3xl flex flex-col items-center justify-center p-10 bg-[#2b2b2c] lg:h-[400px] h-[550px]">
              <div className="w-[100px]">
                <img
                  alt="def"
                  src={grapicDesign}
                  className="w-full h-full"
                />
              </div>
              <span className="text-white poppins font-bold text-xl p-2">
                Graphic Design
              </span>
              <p className="text-gray-300 poppins p-1 text-center">
                At ClusterCode, we create compelling graphic designs that
                elevate your brand, communicate your message and captivate your
                audience. From logos to marketing assets our designs are crafted
                to leave a lasting impression.
              </p>
              <Link
                spy={true}
                smooth={true}
                to="Contact"
                className="bg-[#cd9705] py-1 px-3 ms-1 rounded-md font-semibold poppins mt-4 cursor-pointer"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}
