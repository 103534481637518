import React from "react";
import vision from "../assets/vision.jpg";
import mision from "../assets/mision.jpg";
import deliveringResult from "../assets/delivering results.jpg";
import grouwthThrught from "../assets/growing through force .jpg";
import innovative from "../assets/inovative.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import danula from "../assets/employee-pic/danula.jpg";
import rimas from "../assets/employee-pic/rimas.png";
import daham from "../assets/employee-pic/daham.png";
import tajith from "../assets/employee-pic/tajith.png";
import bimbisha from "../assets/employee-pic/bimbisha.png";
import shamil from "../assets/employee-pic/shamil.png";
import sandeep from "../assets/employee-pic/sandeep.png";
import sachintha from "../assets/employee-pic/sachintha2.png";
import kawshal from "../assets/employee-pic/kawshal.jpg";
import kavindu from "../assets/employee-pic/kavindu.jpg";

export default function AboutUs() {
  return (
    <div
      className="w-full min-h-svh bg-[#1e1e1e] flex justify-center flex-col gap-10 items-center"
      id="About"
    >
      <div className="pt-[100px] w-5/6 flex flex-col gap-2">
        <span
          className="text-white font-bold poppins text-4xl w-full text-center pb-10 underline underline-offset-4"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          About <span className="text-[#cd9705]">Us</span>
        </span>
        <span
          className="text-white font-bold poppins text-2xl"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          ClusterCode
        </span>
        <p
          className="text-gray-300 poppins"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          Welcome to ClusterCode Software Solutions, We offer exceptional
          software solutions, backed by skilled developers, robust processes,
          and a client-centric approach. We prioritize clear communication,
          effective project management, and security, ensuring data privacy and
          growth. Our team creates innovative software solutions for various
          applications, utilizing advanced technology and business goals to
          enhance productivity, security, and long-term success, from concept to
          deployment..
        </p>
      </div>

      <div className="w-5/6 flex flex-col lg:flex-row md:flex-col h-fit pt-10 justify-around gap-10">
        <div
          className="w-full rounded-md flex flex-col items-start bg-[#2b2b2c] pb-5"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <img
            src={vision}
            className="w-full h-[300px] lg:block md:block hidden"
            alt="vision"
          />
          <span className="text-white poppins font-bold text-xl px-5 pt-5">
            Vision
          </span>
          <p className="text-gray-300 poppins px-5 p-1">
            Taking strength from tradition and adapting to the modern world,
            collectively and relentlessly stepping into a new dimension of the
            digital world.
          </p>
        </div>
        <div
          className="w-full rounded-md flex flex-col items-start bg-[#2b2b2c] pb-5"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <img
            src={mision}
            className="w-full h-[300px] lg:block md:block hidden"
            alt="mission"
          />
          <span className="text-white poppins font-bold text-xl px-5 pt-5">
            Mission
          </span>
          <p className="text-gray-300 poppins px-5 p-1">
            ClusterCode empowers businesses with innovative digital solutions,
            blending tradition with modern technology to deliver
            high-performance web, mobile, and desktop applications. We focus on
            quality, security, and continuous improvement to help clients thrive
            in the digital world and achieve lasting success.
          </p>
        </div>
      </div>

      <div className="w-5/6 flex h-fit pt-10 justify-around flex-col gap-16">
        {/* right image */}
        <div className="flex lg:h-[400px] flex-col lg:flex-row md:flex-col gap-5 md:gap-5 lg:gap-0">
          <div className="lg:w-2/3 md:w-full w-full flex flex-col justify-center lg:pe-10 gap-5">
            <span
              className="text-white poppins font-bold text-2xl"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              Reliable Solutions, Tailored for Success
            </span>
            <p
              className="text-gray-300 poppins"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              we provide reliable services at reasonable charges, offering
              innovative and flexible solutions tailored to your needs. Our
              dedicated technical support team ensures seamless assistance,
              while we prioritize the best communication between clients and our
              team. With our commitment to delivering appropriate solutions, we
              help your business stay ahead in the digital landscape.
            </p>
          </div>
          <div
            className="lg:w-2/3 md:w-full w-full"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img
              src={deliveringResult}
              className="h-full w-full rounded-md"
              alt="deliveringResult"
            />
          </div>
        </div>
      </div>

      <div className="pt-10 w-5/6 flex flex-col gap-2">
        <span
          className="text-white font-bold poppins text-4xl w-full text-center pb-10 underline underline-offset-4"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          About <span className="text-[#cd9705]">Our Team</span>
        </span>
        <Carousel
          data-aos="zoom-in"
          data-aos-duration="1000"
          showArrows={true}
          autoPlay={true}
          emulateTouch={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          interval={5000}
          preventMovementUntilSwipeScrollTolerance={true}
          className="md:hidden lg:block hidden"
        >
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={danula}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Danula Gunawardana
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Chief Executive Officer{" "}
              </span>
            </div>
            {/* <div className="w-60 flex flex-col">
              <img className="w-60 h-60 rounded-md" src={rimas} alt="profile pic" />
              <span className="text-white pt-2 text-lg poppins">
                Rimas Rajapaksha
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & ......{" "}
              </span>
            </div> */}
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={daham}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Daham Bandara
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Chief Technology Officer{" "}
              </span>
            </div>
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={sandeep}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Sandeep Kavinda
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Full Stack Developer{" "}
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={shamil}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Shamil Kayil
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Project Manager{" "}
              </span>
            </div>
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={kawshal}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Kaushal Silva
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Frontend Developer{" "}
              </span>
            </div>
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={tajith}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Tajith Senura
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Quality Assurance Engineer{" "}
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={sachintha}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Sachintha Dilshan
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Marketing and Sales Lead{" "}
              </span>
            </div>
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={bimbisha}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Bimbisha Indurugalla
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Backend Developer{" "}
              </span>
            </div>
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={kavindu}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Kavindu Dineka
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Full Stack Developer{" "}
              </span>
            </div>
          </div>
        </Carousel>
        <Carousel
          data-aos="zoom-in"
          data-aos-duration="1000"
          showArrows={true}
          autoPlay={true}
          emulateTouch={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          interval={5000}
          preventMovementUntilSwipeScrollTolerance={true}
          className="md:block lg:hidden block"
        >
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={danula}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Danula Gunawardana
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Chief Executive Officer{" "}
              </span>
            </div>
          </div>
          {/* <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img className="w-60 h-60 rounded-md" src={rimas} alt="profile pic" />
              <span className="text-white pt-2 text-lg poppins">
                Rimas Rajapaksha
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & ......{" "}
              </span>
            </div>
          </div> */}
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={daham}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Daham Bandara
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Chief Technology Officer{" "}
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={shamil}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Shamil Kayil
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Project Manager{" "}
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={sandeep}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Sandeep Kavinda
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Full Stack Developer{" "}
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={tajith}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Tajith Senura
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Quality Assurance Engineer{" "}
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={kawshal}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Kaushal Silva
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Frontend Developer{" "}
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={sachintha}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Sachintha Dilshan
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Marketing and Sales Lead{" "}
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={bimbisha}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Bimbisha Indurugalla
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Backend Developer{" "}
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-center pb-16 gap-16">
            <div className="w-60 flex flex-col">
              <img
                className="w-60 h-60 rounded-md"
                src={kavindu}
                alt="profile pic"
              />
              <span className="text-white pt-2 text-lg poppins">
                Kavindu Dineka
              </span>
              <span className="text-white pt-2 text-xs poppins">
                FOUNDER & Full Stack Developer{" "}
              </span>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}
