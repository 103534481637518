import { Backdrop, CircularProgress, TextField } from "@mui/material";
import callImage from "../assets/call-center-5.jpg";
import React, { useState } from "react";
import Swal from "sweetalert2";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // const baseUrl = "https://clustercodecc.com";
  // const baseUrl = "http://localhost:8000";

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendEmail = async () => {
    if (name.length == 0 || name == "") {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        text: "Please enter your Name",
      });
    } else if (email.length == 0 || email == "") {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        text: "Please enter your Email",
      });
    } else if (!validateEmail(email)) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        text: "Please enter valid Email",
      });
    } else if (message.length == 0 || message == "") {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        text: "Please enter your Email",
      });
    } else {
      let dataSend = {
        email: email,
        message: message,
        name: name,
        mobile: mobile,
      };

      console.log(dataSend);

      try {
        setLoading(true);

        const res = await fetch(
          "https://clustercode-backend.com.clustercodecc.com/email/sendEmail",
          {
            method: "POST",
            body: JSON.stringify(dataSend),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        // Parse the JSON response
        const result = await res.json();

        setLoading(false);

        if (result.success) {
          // alert("Email sent successfully!");
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Email sent successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          alert("Failed to send email. Please try again.");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error sending email:", error);
        alert("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div
      className="w-full min-h-svh bg-[#1e1e1e] flex flex-col gap-10 items-center"
      id="Contact"
    >
      <div className="pt-[100px] w-5/6 h-full flex flex-col gap-2">
        <span
          className="text-white font-bold poppins text-4xl w-full text-center pb-10 underline underline-offset-4"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          Contact <span className="text-[#cd9705]">Us</span>
        </span>
        <div className="flex w-full h-full flex-col lg:flex-row md:justify-center lg:justify-center">
          {/* <div className="w-full flex items-center"> */}
          <div
            className="w-full lg:hidden block h-full flex items-center p-3"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img
              src={callImage}
              className={"w-full rounded-r-lg object-cover object-center image"}
              alt="call girl"
            />
          </div>
          <div className="w-full lg:w-2/5 h-full flex flex-col py-10 gap-5 bg-[#2b2b2c] lg:px-10 md:px-10 px-4 rounded-l-lg">
            <span
              className="text-white w-full text-center pb-3 poppins font-bold"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              Simply contact us by sending an Email
            </span>
            <TextField
              data-aos="zoom-in"
              data-aos-duration="1000"
              onChange={(e) => setName(e.target.value)}
              label="Your Name"
              variant="outlined"
              helperText="*Required"
              inputMode="text"
              type="text"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  "& fieldset": {
                    borderColor: "#cd9705",
                  },
                  "&:hover fieldset": {
                    borderColor: "#cd9705",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#cd9705",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#cd9705",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#cd9705",
                },
                "& .MuiFormHelperText-root": {
                  color: "#cd9705",
                },
              }}
            />
            <TextField
              data-aos="zoom-in"
              data-aos-duration="1000"
              label="Your Email"
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              helperText="*Required"
              inputMode="email"
              type="email"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  "& fieldset": {
                    borderColor: "#cd9705",
                  },
                  "&:hover fieldset": {
                    borderColor: "#cd9705",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#cd9705",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#cd9705",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#cd9705",
                },
                "& .MuiFormHelperText-root": {
                  color: "#cd9705",
                },
              }}
            />
            <TextField
              data-aos="zoom-in"
              data-aos-duration="1000"
              label="Your Mobile"
              onChange={(e) => setMobile(e.target.value)}
              variant="outlined"
              inputMode="tel"
              type="tel"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  "& fieldset": {
                    borderColor: "#cd9705",
                  },
                  "&:hover fieldset": {
                    borderColor: "#cd9705",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#cd9705",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#cd9705",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#cd9705",
                },
                "& .MuiFormHelperText-root": {
                  color: "#cd9705",
                },
              }}
            />
            <TextField
              data-aos="zoom-in"
              data-aos-duration="1000"
              label="Message"
              onChange={(e) => setMessage(e.target.value)}
              variant="outlined"
              helperText="*Required"
              type="text"
              inputMode="text"
              multiline
              rows={4}
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  "& fieldset": {
                    borderColor: "#cd9705",
                  },
                  "&:hover fieldset": {
                    borderColor: "#cd9705",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#cd9705",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#cd9705",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#cd9705",
                },
                "& .MuiFormHelperText-root": {
                  color: "#cd9705",
                },
              }}
            />
            <button
              data-aos="zoom-in"
              data-aos-duration="1000"
              className="bg-[#cd9705] rounded-md py-2 poppins font-semibold"
              onClick={() => sendEmail()}
            >
              Send Message
            </button>
          </div>
          <div className="w-3/5 lg:block hidden" data-aos="zoom-in" data-aos-duration="1000">
            <img
              src={callImage}
              className={
                "w-full h-full rounded-r-lg object-cover object-center image"
              }
              alt="call girl"
            />
          </div>

          <Backdrop
            sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>
    </div>
  );
}
