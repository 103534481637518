import Contact from "./components/Contact";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Navbar from "./components/Navbar";
import Products from "./components/Products";
import Footer from "./components/Footer";
import Snowfall from "react-snowfall";
import "./css/wp.css";
import whatsappIcon from "./assets/whatsapp-brands-solid-white.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

function App() {
  return (
    <div className="bg-[#1e1e1e] scroll-smooth">
      <Navbar />
      <Home />
      <Products />
      <AboutUs />
      <Contact />
      <Footer />
      <a
        target="_blank"
        href="https://api.whatsapp.com/send?phone=94772064930&text=Hi"
        class="btn-whatsapp-pulse cursor-pointer z-10 h-[40px] w-[40px]"
      >
        <img
          src={whatsappIcon}
          className="h-[40px] w-[40px] absolute"
          alt="whatsapp icon"
        />
      </a>

      <Snowfall
        snowflakeCount={100}
        style={{
          position: "fixed",
          width: "100vw",
          height: "100vh",
        }}
      />
    </div>
  );
}

export default App;
